import React, { useEffect, useState } from 'react';

function HTMLPage({ url }) {
    const [htmlContent, setHtmlContent] = useState('');

    useEffect(() => {
        fetch(url)
        .then((response) => response.text())
        .then((html) => {
            setHtmlContent(html);
        })
        .catch((error) => console.error('Error loading HTML:', error));
    }, [url]);

    return (
        <div
        className="html-page"
        dangerouslySetInnerHTML={{ __html: htmlContent }}
        />
    );
}


export default HTMLPage;